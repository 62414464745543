<template>
    <tablecustom
        :objParams='objParams'
        :dataParams='dataParams'
        :rows='rows'
        :columns='columns'
        :createdform="true" 
        :showpag="true"
        @create="create"
        @open="open"
    />
</template>

<script>
import tablecustom from '@/components/globaltable/index.vue'
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js' // надсилання логу в телеграм
import { Notion } from '../../API';

let apiServe = new Notion()

export default {
    data() {
        return {
            dataParams: {},
            showCard: false,
            objCard: {},
            objParams: {
                page: '',
            },
            rows: [],
            columns: [
                {
                    name: "ID",
                    text: "nomenclatureId",
                    align: "left",
                    status: true,
                },
            ]
        }
    },

    components: {
        tablecustom,
    },
    created() {
        this.getData()
    },
    methods: {
        create() {
            window.location.pathname = "/notion/create"
        },
        getData(page) {
            // this.objParams.page = page != undefined ? page : '1';
            apiServe.getDocs(this.objParams).then(result => {
                if(result.status === 'done') {
                    this.rows = result.data.items;
                } else {
                    this.handleError(result.error[0])
                    var errorText = ''; 
                    var statusRequest = result.status;
                    var api = 'getData';
                    var fileRequest = '';
                    var params = id;
                    var response = result
                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
        open(e) {
            console.log(e)
        },
        handleError(error) {
            //відображення помилки
            switch(error){
                case 'condition':
                    this.$toast.error(this.t('translation_key'));
                    break;
            }
        },
    },
}
</script>

<style>
</style>